import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  getAddCreditCardLink,
  getTeacherByUID,
} from "../utils/requests/teachers"
import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Input,
  User,
} from "@nextui-org/react"

import classes from "../styles/TeacherDetails.module.scss"
import { formatReadablePhoneNumber } from "../utils/phoneNumber"
import { getOptionalValue } from "../utils/values"
import DetailsItem from "../components/DetailsItem/DetailsItem"
import CopyIcon from "../components/Icons/CopyIcon"

const TeacherDetails = () => {
  const [loading, setLoading] = useState(false)
  const [teacher, setTeacher] = useState<TeacherDetails>()
  const [user, setUser] = useState<UserDetails>()
  const [addCreditCardLink, setAddCreditCardLink] = useState<string>("")
  const [addCreditCardError, setAddCreditCardError] = useState<string>("")

  const { id } = useParams()
  const navigate = useNavigate()
  const fetchTeacherDetails = async () => {
    setLoading(true)
    const res = await getTeacherByUID(Number(id))
    setTeacher(res.teacher)
    setUser(res.user)
    setLoading(false)
  }

  useEffect(() => {
    fetchTeacherDetails()
  }, [])

  const onAttachInvoicesAccount = () => {
    if (user?.id) {
      navigate(`/teachers/invoices/attach/${user.id}`)
    }
  }
  const onAddCreditCardPress = async () => {
    setAddCreditCardError("")
    const { url, error } = await getAddCreditCardLink(Number(id))

    if (url) {
      return setAddCreditCardLink(url)
    }

    if (error) {
      return setAddCreditCardError(error.message)
    }
    setAddCreditCardError("Unknown Error")
  }

  const copyLink = () => {
    navigator.clipboard.writeText(addCreditCardLink)
  }

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Card>
          <CardBody className={classes.details}>
            <Accordion
              isCompact
              selectionMode="multiple"
              defaultExpandedKeys={["1", "2", "3"]}
            >
              <AccordionItem key="1" aria-label="Details" title="Details">
                <div className={classes.row}>
                  <DetailsItem label="First Name" value={teacher?.firstName} />

                  <DetailsItem
                    label="Last Name"
                    value={getOptionalValue(teacher?.lastName)}
                  />
                  <DetailsItem
                    label="Email"
                    value={getOptionalValue(user?.email)}
                  />
                  <DetailsItem
                    label="Phone Number"
                    value={
                      user?.phoneNumber
                        ? formatReadablePhoneNumber(user.phoneNumber)
                        : "N/A"
                    }
                  />
                </div>
              </AccordionItem>

              <AccordionItem
                key="2"
                aria-label="Subscription"
                title="Subscription"
              >
                <div className={classes.row}>
                  <DetailsItem
                    label="Is Subscribed"
                    value={!!user?.isSubscribed}
                  />

                  <DetailsItem
                    label="Subscription Price"
                    value={(user?.subscriptionPrice ?? 0).toLocaleString(
                      "he-IL",
                      {
                        style: "currency",
                        currency: "ILS",
                      }
                    )}
                  />

                  <DetailsItem
                    label="Next Charge"
                    value={
                      user?.renewalDate
                        ? new Date(user.renewalDate).toLocaleDateString("he-IL")
                        : "N/A"
                    }
                  />
                  <DetailsItem
                    label="Next Charge Amount"
                    value={(user?.nextChargePrice ?? 0).toLocaleString(
                      "he-IL",
                      {
                        style: "currency",
                        currency: "ILS",
                      }
                    )}
                  />
                  <DetailsItem label="Invoices" value={!!teacher?.invoices} />
                </div>
              </AccordionItem>
              <AccordionItem key="3" aria-label="Lessons" title="Lessons">
                <div className={classes.details}>
                  <div className={classes.row}>
                    <DetailsItem
                      label="Lesson Price"
                      value={getOptionalValue(
                        teacher?.lessonPrice
                          ? teacher?.lessonPrice.toLocaleString("he-IL", {
                              style: "currency",
                              currency: "ILS",
                            })
                          : "N/A"
                      )}
                    />
                    <DetailsItem
                      label="Lesson Duration"
                      value={getOptionalValue(teacher?.lessonDuration)}
                    />

                    <DetailsItem
                      label="Max Lessons Per Week"
                      value={teacher?.maxLessonsPerWeek}
                    />
                    <DetailsItem
                      label="Max Dup. Requests"
                      value={teacher?.maxDuplicatedRequests}
                    />
                  </div>

                  <div className={classes.row}>
                    <DetailsItem
                      label="Allow Double Booking?"
                      value={!!teacher?.allowDoubleBooking}
                    />
                    <DetailsItem
                      label="Block Jewish Holidays?"
                      value={!!teacher?.blockJewishHolidays}
                    />
                    <DetailsItem
                      label="Push Events?"
                      value={!!teacher?.pushEvents}
                    />
                  </div>
                </div>
              </AccordionItem>
              <AccordionItem
                key="4"
                aria-label="Notifications"
                title="Notifications"
              >
                <div className={classes.row}>
                  <DetailsItem label="SMS" value={!!teacher?.canGetSMS} />
                  <DetailsItem label="Push" value={!!teacher?.canGetPush} />
                </div>
              </AccordionItem>
            </Accordion>
          </CardBody>
        </Card>
      </div>
      <div className={classes.right}>
        <Card>
          <CardHeader>Quick Actions</CardHeader>
          <Divider />
          <CardBody>
            <div className={classes.actions}>
              <Button
                fullWidth
                variant="flat"
                disabled={!user?.id}
                onClick={onAttachInvoicesAccount}
              >
                Attach Invoices Account
              </Button>

              <Button fullWidth color="danger">
                Unsubscribe
              </Button>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Subscription</CardHeader>
          <Divider />
          <CardBody>
            <div className={classes.actions}>
              <Button
                fullWidth
                variant="flat"
                disabled={!user?.id}
                onClick={onAddCreditCardPress}
              >
                Add Credit Card
              </Button>

              {addCreditCardLink && (
                <div className={classes.row}>
                  <Button
                    isIconOnly
                    variant="flat"
                    aria-label="Copy Link"
                    onPress={copyLink}
                    size="lg"
                  >
                    <CopyIcon />
                  </Button>

                  <Input
                    isDisabled
                    type="text"
                    label="Link"
                    size="sm"
                    defaultValue={addCreditCardLink}
                  />
                </div>
              )}
              {addCreditCardError && (
                <p className="text-small text-red-600	">{addCreditCardError}</p>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default TeacherDetails
