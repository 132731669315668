export const TOKEN_COOKIE = "dio_token"

/**
 * Sets a cookie.
 * @param name The name of the cookie.
 * @param value The value of the cookie.
 * @param days The number of days until the cookie expires.
 */
const setCookie = (name: string, value: string, days: number = 60): void => {
  let expires = ""
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/"
}

/**
 * Gets a cookie by name.
 * @param name The name of the cookie to find.
 * @returns The value of the cookie if found, otherwise an empty string.
 */
const getCookie = (name: string): string => {
  const nameEQ = name + "="
  const ca = document.cookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return ""
}

/**
 * Deletes a cookie by name.
 * @param name The name of the cookie to delete.
 */
const deleteCookie = (name: string): void => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
}

export { setCookie, getCookie, deleteCookie }
