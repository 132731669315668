import { Card, CardBody, CardHeader, Divider } from "@nextui-org/react"
import classes from "./StatsCard.module.scss"

interface StatsCardProps {
  title: string
  value: number
  isPrice?: boolean
}

const StatsCard = (props: StatsCardProps) => {
  const { title, value, isPrice } = props
  return (
    <Card className={classes.root}>
      <CardHeader>
        <p>{title}</p>
      </CardHeader>
      <Divider />
      <CardBody>
        <h2>
          {value.toLocaleString(
            "he-IL",
            isPrice ? { style: "currency", currency: "ILS" } : undefined
          )}
        </h2>
      </CardBody>
    </Card>
  )
}

export default StatsCard
