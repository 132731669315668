import { BASE_URL, requests } from "./base"

const SETTINGS = `${BASE_URL}/settings`
const STUDENTS = `${BASE_URL}/teacher/students`

export const getSettings = async () => {
  const res = await requests.get<Settings>(SETTINGS)
  return res
}

export const getStudents = async () => {
  const res = await requests.get<StudentDetails[]>(STUDENTS)
  return res
}
