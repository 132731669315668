import classes from "../styles/Loading.module.scss"

const Loading = () => {
  return (
    <div className={classes.root}>
      <h1>Loading...</h1>
    </div>
  )
}

export default Loading
