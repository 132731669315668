import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Input,
} from "@nextui-org/react"
import classes from "../../styles/NewTeacher.module.scss"
import { useEffect, useState } from "react"
import {
  createNewTeacher,
  getTeacherByUID,
} from "../../utils/requests/teachers"
import { useNavigate, useParams } from "react-router-dom"
import { attachInvoicesAccount } from "../../utils/requests/invoices"

const AttachInvoices = () => {
  const [loading, setLoading] = useState(false)
  const [teacher, setTeacher] = useState<TeacherDetails>()
  const [user, setUser] = useState<UserDetails>()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const navigate = useNavigate()
  const { id } = useParams()

  const fetchTeacherDetails = async () => {
    setLoading(true)
    const res = await getTeacherByUID(Number(id))
    setTeacher(res.teacher)
    setUser(res.user)
    setLoading(false)
  }

  useEffect(() => {
    fetchTeacherDetails()
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await attachInvoicesAccount(
      email,
      password,
      Number(teacher?.id)
    )

    setLoading(false)

    if (!res.success) {
      return
    }

    navigate(-1)
  }
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader>New Teacher</CardHeader>
        <Divider />
        <CardBody>
          <form onSubmit={onSubmit} className={classes.form}>
            <Input
              type="tel"
              label="Email"
              disabled={loading}
              required
              value={email}
              onInput={(e) => setEmail(e.currentTarget.value)}
            />
            <Input
              type="text"
              label="Password"
              disabled={loading}
              required
              value={password}
              onInput={(e) => setPassword(e.currentTarget.value)}
            />

            <Button type="submit" fullWidth disabled={loading}>
              Attach Invoices Account
            </Button>
          </form>
        </CardBody>
      </Card>
    </div>
  )
}

export default AttachInvoices
