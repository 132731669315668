import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Spinner,
  Chip,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

interface Props {
  leads: Lead[]
  pages: number
  resultsCount: number
  page: number
  loading: boolean
  onPageChange(page: number): void
  onStatusChange(leadId: number, newStatus: Lead["status"]): void
  onPriorityChange(leadId: number, newPriority: number): void
}

export const priorityMapping: Record<number, string> = {
  1: "Lowest",
  2: "Low",
  3: "Medium",
  4: "Heigh",
  5: "Highest",
}
export const statuses: Lead["status"][] = [
  "PENDING",
  "RECEIVED",
  "CONTACTED",
  "REPLIED",
  "JOINED",
  "REJECTED",
]
export const statusLabels: Record<Lead["status"], string> = {
  PENDING: "Pending",
  RECEIVED: "Received",
  CONTACTED: "Contacted",
  REPLIED: "Replied",
  JOINED: "Joined",
  REJECTED: "Rejected",
}
export const getColorByStatus = (
  status?: Lead["status"]
): "primary" | "default" | "secondary" | "success" | "warning" | "danger" => {
  if (status === "JOINED") {
    return "success"
  }
  if (status === "REJECTED") {
    return "danger"
  }
  if (status === "PENDING" || status === "RECEIVED") {
    return "primary"
  }
  return "warning"
}
export const getColorByPriority = (
  priority?: number
): "primary" | "default" | "secondary" | "success" | "warning" | "danger" => {
  switch (priority) {
    case 1:
    case 2: {
      return "success"
    }
    case 3: {
      return "primary"
    }
    case 4: {
      return "warning"
    }
    case 5: {
      return "danger"
    }
    default: {
      return "default"
    }
  }
}

const LeadsTable = (props: Props) => {
  const {
    leads,
    loading,
    pages,
    resultsCount,
    page,
    onPageChange,
    onStatusChange,
    onPriorityChange,
  } = props

  const navigate = useNavigate()

  const getValue = (value: any) => {
    if (loading) {
      return <Spinner size="sm" />
    }
    return value
  }

  const bottomContent = useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <span className="w-[30%] text-small text-default-400">
          {`Showing ${leads.length} out of ${resultsCount} in total`}
        </span>
        {pages > 0 && (
          <Pagination
            isCompact
            showControls
            showShadow
            color="primary"
            page={page}
            total={pages}
            onChange={onPageChange}
          />
        )}
      </div>
    )
  }, [page, pages, leads, resultsCount])

  return (
    <>
      <Table aria-label="Example static collection table" isCompact>
        <TableHeader>
          <TableColumn>ID</TableColumn>
          <TableColumn>First Name</TableColumn>
          <TableColumn>Last Name</TableColumn>
          <TableColumn>Phone Number</TableColumn>
          <TableColumn>Status</TableColumn>
          <TableColumn>Priority</TableColumn>
          <TableColumn>Comments</TableColumn>
          <TableColumn>Teaching Area</TableColumn>
          <TableColumn>First Seen</TableColumn>
          <TableColumn>First Message</TableColumn>
          <TableColumn>Reminder Date</TableColumn>
          <TableColumn>Reminders Sent</TableColumn>
          <TableColumn>Joined Date</TableColumn>
        </TableHeader>
        <TableBody emptyContent={"No rows to display."}>
          {leads.map((lead, index) => {
            return (
              <TableRow
                key={`${index}`}
                onClick={() => navigate(`/lead/${lead.visibleId}`)}
              >
                <TableCell>{getValue(lead.visibleId ?? lead.id)}</TableCell>
                <TableCell>{getValue(lead.firstName)}</TableCell>
                <TableCell>{getValue(lead.lastName)}</TableCell>
                <TableCell>{getValue(lead.phoneNumber)}</TableCell>
                <TableCell>
                  <Dropdown placement="bottom-end">
                    <DropdownTrigger>
                      <Chip
                        color={getColorByStatus(lead.status)}
                        variant="flat"
                      >
                        {statusLabels[lead.status]}
                      </Chip>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Profile Actions" variant="flat">
                      {statuses.map((statusItem) => (
                        <DropdownItem
                          key={statusItem}
                          onClick={() => onStatusChange(lead.id, statusItem)}
                        >
                          <Chip
                            color={getColorByStatus(statusItem)}
                            variant="flat"
                          >
                            {statusLabels[statusItem]}
                          </Chip>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </TableCell>
                <TableCell>
                  <Dropdown placement="bottom-end">
                    <DropdownTrigger>
                      <Chip
                        color={getColorByPriority(lead.priority ?? 3)}
                        variant="flat"
                      >
                        {priorityMapping[lead.priority ?? 3]}
                      </Chip>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Profile Actions" variant="flat">
                      {Object.entries(priorityMapping).map(
                        ([priority, label]) => (
                          <DropdownItem
                            key={priority}
                            onClick={() =>
                              onPriorityChange(lead.id, Number(priority))
                            }
                          >
                            <Chip
                              color={getColorByPriority(Number(priority))}
                              variant="flat"
                            >
                              {label}
                            </Chip>
                          </DropdownItem>
                        )
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </TableCell>
                <TableCell>
                  <div
                    style={{ height: 40, width: 200, overflow: "hidden" }}
                    dangerouslySetInnerHTML={{ __html: lead?.comments ?? "" }}
                  ></div>
                </TableCell>
                <TableCell>{getValue(lead.teachingArea)}</TableCell>
                <TableCell>
                  {getValue(
                    lead?.firstSeenDate
                      ? new Date(lead?.firstSeenDate)?.toLocaleDateString?.(
                          "he-IL"
                        ) ?? "-"
                      : "-"
                  )}
                </TableCell>
                <TableCell>
                  {getValue(
                    lead?.firstMessageDate
                      ? new Date(lead?.firstMessageDate)?.toLocaleDateString?.(
                          "he-IL"
                        ) ?? "-"
                      : "-"
                  )}
                </TableCell>
                <TableCell>
                  {getValue(
                    lead?.reminderDate
                      ? new Date(lead?.reminderDate)?.toLocaleDateString?.(
                          "he-IL"
                        ) ?? "-"
                      : "-"
                  )}
                </TableCell>
                <TableCell>{getValue(lead.remindersSent)}</TableCell>
                <TableCell>
                  {getValue(
                    lead?.joinedDate
                      ? new Date(lead?.joinedDate)?.toLocaleDateString?.(
                          "he-IL"
                        ) ?? "-"
                      : "-"
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {bottomContent}
    </>
  )
}
export default LeadsTable
