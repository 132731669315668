import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
} from "@nextui-org/react"
import Logo from "./Logo"
import { useAuth } from "../context/AuthContext"

const links = [
  {
    label: "Teachers",
    href: "/teachers",
  },
  {
    label: "Students",
    href: "/students",
  },
  {
    label: "Leads",
    href: "/leads",
  },
]

const Header = () => {
  const {
    auth: { isLoggedIn, user },
    logout,
  } = useAuth()

  if (!isLoggedIn || user?.role !== "admin") {
    return null
  }
  return (
    <div style={{ maxWidth: "calc(90% + 24px)", margin: "auto" }}>
      <Navbar maxWidth="full">
        <NavbarBrand className="flex gap-3">
          <Link href="/">
            <Logo height={35} width={17} />
          </Link>
          <p className="font-bold text-inherit">Dashboard</p>
        </NavbarBrand>
        <NavbarContent className="sm:flex gap-4" justify="center">
          {links.map((link) => {
            const isActive = `/${window.location.pathname}` === link.href
            return (
              <NavbarItem isActive={isActive}>
                <Link
                  color={isActive ? "primary" : "foreground"}
                  href={link.href}
                >
                  {link.label}
                </Link>
              </NavbarItem>
            )
          })}
        </NavbarContent>
        <NavbarContent justify="end">
          <NavbarItem>
            <Button color="danger" variant="flat" onClick={logout}>
              Logout
            </Button>
          </NavbarItem>
        </NavbarContent>
      </Navbar>
    </div>
  )
}
export default Header
