import StatsCard from "../components/StatsCard/StatsCard"
import { Accordion, AccordionItem } from "@nextui-org/react"
import useStats from "../hooks/useStats"
import classes from "../styles/Home.module.scss"

const Home = () => {
  const {
    teachers,
    students,
    events,
    liveEvents,
    liveTests,
    subscriptions,
    totalCompanyFee,
  } = useStats()

  return (
    <div className={classes.root}>
      <Accordion selectionMode="multiple" defaultExpandedKeys={["2"]}>
        <AccordionItem key="1" aria-label="KPIs" title="KPIs">
          <div className={classes.stats}>
            <StatsCard title="Subscriptions" value={subscriptions} isPrice />
            <StatsCard title="Fees" value={totalCompanyFee} isPrice />
            <StatsCard title="Teachers" value={teachers} />
            <StatsCard title="Students" value={students} />
            <StatsCard title="Total Lessons" value={events} />
          </div>
        </AccordionItem>
        <AccordionItem key="2" aria-label="Live Data" title="Live Data">
          <div className={classes.stats}>
            <StatsCard title="Live Events" value={liveEvents} />
            <StatsCard title="Live Tests" value={liveTests} />
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default Home
