import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom"
import Home from "../pages/Home"
import NotFound from "../pages/404"
import Login from "../pages/Login"
import { useAuth } from "../context/AuthContext"
import Loading from "../pages/Loading"
import NoAccess from "../pages/NoAccess"
import Teachers from "../pages/Teachers"
import TeacherDetails from "../pages/TeacherDetails"
import NewTeacher from "../pages/NewTeacher"
import AttachInvoices from "../pages/Invoices/AttachInvoices"
import Leads from "../pages/Leads"
import LeadDetails from "../pages/LeadDetails"
import NewLead from "../pages/NewLead"

const noAccessRoutes = createBrowserRouter([
  {
    path: "*",
    Component: NoAccess,
  },
])
const loadingRoutes = createBrowserRouter([
  {
    path: "*",
    Component: Loading,
  },
])
const loggedOutRouted = createBrowserRouter([
  {
    path: "/",
    Component: Login,
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
])
const loggedInRouted = createBrowserRouter([
  {
    path: "/",
    Component: Home,
  },
  {
    path: "/teachers",
    Component: Teachers,
  },
  {
    path: "/teachers/:id",
    Component: TeacherDetails,
  },
  {
    path: "/teachers/new",
    Component: NewTeacher,
  },
  {
    path: "/teachers/invoices/attach/:id",
    Component: AttachInvoices,
  },
  {
    path: "/leads",
    Component: Leads,
  },
  {
    path: "/lead/:id",
    Component: LeadDetails,
  },
  {
    path: "/leads/new",
    Component: NewLead,
  },
  {
    path: "*",
    Component: NotFound,
  },
])

const Router = () => {
  const {
    auth: { isLoggedIn, loading, user },
  } = useAuth()

  const getRouter = () => {
    if (loading) {
      return loadingRoutes
    }
    if (isLoggedIn) {
      console.log(user)
      if (user?.role !== "admin") {
        return noAccessRoutes
      }
      return loggedInRouted
    }

    return loggedOutRouted
  }
  return <RouterProvider router={getRouter()} />
}

export default Router
