import { TOKEN_COOKIE, getCookie } from "../cookies"

export const BASE_URL = process.env.REACT_APP_BASE_URL

export interface Response {
  success: boolean
  error?: {
    code: string
    message: string
    field?: string
  }
}

interface RequestOptions {
  headers?: object
  signal?: AbortSignal
}

export const baseRequest = async (
  method: string,
  url: string,
  body?: object,
  options?: RequestOptions
) => {
  const token = getCookie(TOKEN_COOKIE)
  console.log(`[${method}] ${url}`)
  const res = await fetch(url, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      "Content-Type": "application/json",
      "X-App-Type": "teacher",
      ...(!!token && { Authorization: `Bearer ${token}` }),
      ...options?.headers,
    },

    ...(options?.signal && { signal: options.signal }),
  })

  const json = await res.json()
  return json
}

export const requests: Record<
  string,
  <T = Response>(
    url: string,
    body?: object,
    options?: RequestOptions
  ) => Promise<T>
> = {
  post: (url: string, body?: object, options?: RequestOptions) =>
    baseRequest("POST", url, body, options),
  get: (url: string, _?: object, options?: RequestOptions) =>
    baseRequest("GET", url, undefined, options),
  delete: (url: string, body?: object, options?: RequestOptions) =>
    baseRequest("DELETE", url, body, options),
  patch: (url: string, body?: object, options?: RequestOptions) =>
    baseRequest("PATCH", url, body, options),
}
