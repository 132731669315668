import { Chip } from "@nextui-org/react"
import { getOptionalValue } from "../../utils/values"
import classes from "./DetailsItem.module.scss"

interface DetailsItemProps {
  label: string
  value: any
}

const Value = (props: { value: any }) => {
  const { value } = props
  if (typeof value === "boolean") {
    return (
      <Chip variant="flat" color={value ? "success" : "danger"}>
        {value ? "Yes" : "No"}
      </Chip>
    )
  }

  return <h4>{getOptionalValue(value)}</h4>
}

const DetailsItem = (props: DetailsItemProps) => {
  const { label, value } = props

  return (
    <div className={classes.root}>
      <p>{label}</p>
      <Value value={value} />
    </div>
  )
}

export default DetailsItem
