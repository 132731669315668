import React from "react"
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Spinner,
  Chip,
} from "@nextui-org/react"
import { useNavigate } from "react-router-dom"

interface Props {
  teachers: TeachersSearchResult
  pages: number
  page: number
  loading: boolean
  onPageChange(page: number): void
}

const TeachersTable = (props: Props) => {
  const { teachers, loading, pages, page, onPageChange } = props

  const navigate = useNavigate()

  const getValue = (value: any) => {
    if (loading) {
      return <Spinner size="sm" />
    }
    return value
  }

  return (
    <Table
      aria-label="Example static collection table"
      isCompact
      bottomContent={
        pages > 0 ? (
          <div className="flex w-full justify-center">
            <Pagination
              isCompact
              showControls
              showShadow
              color="primary"
              page={page}
              total={pages}
              onChange={(page) => onPageChange(page)}
            />
          </div>
        ) : null
      }
    >
      <TableHeader>
        <TableColumn>Subscription</TableColumn>
        <TableColumn>First Name</TableColumn>
        <TableColumn>Last Name</TableColumn>
        <TableColumn>Phone Number</TableColumn>
        <TableColumn>Renewal Date</TableColumn>
        <TableColumn>Active Students</TableColumn>
      </TableHeader>
      <TableBody emptyContent={"No rows to display."}>
        {teachers.map((teacher, index) => {
          console.log(teacher.isSubscribed)
          return (
            <TableRow
              key={`${index}`}
              onClick={() => navigate(`/teachers/${teacher.uid}`)}
            >
              <TableCell>
                <Chip
                  color={teacher.isSubscribed ? "success" : "danger"}
                  variant="flat"
                >
                  {teacher.isSubscribed ? "Active" : "Disabled"}
                </Chip>
              </TableCell>
              <TableCell>{getValue(teacher.firstName)}</TableCell>
              <TableCell>{getValue(teacher.lastName)}</TableCell>
              <TableCell>{getValue(teacher.phoneNumber)}</TableCell>
              <TableCell>
                {getValue(
                  teacher?.renewalDate
                    ? new Date(teacher?.renewalDate)?.toLocaleDateString?.(
                        "he-IL"
                      ) ?? "-"
                    : "-"
                )}
              </TableCell>
              <TableCell>{getValue(teacher?.activeStudentsCount)}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
export default TeachersTable
