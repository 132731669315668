import { useState } from "react"
import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
} from "@nextui-org/react"
import classes from "../styles/Leads.module.scss"
import { useNavigate } from "react-router-dom"
import LeadsTable, {
  getColorByPriority,
  getColorByStatus,
  priorityMapping,
  statuses,
  statusLabels,
} from "../components/Tables/LeadsTable"
import { useLeadsSearch } from "../hooks/useLeadsSearch"
import { updateLead } from "../utils/requests/leads"

const Leads = () => {
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState("")
  const [orderBy, setOrderBy] = useState("id")
  const [orderDirection, setOrderDirection] = useState<"DESC" | "ASC">("DESC")
  const [statusFilter, setStatusFilter] = useState<Lead["status"] | null>(null)
  const [priorityFilter, setPriorityFilter] = useState<number | null>(null)

  const { leads, totalPages, totalResults, loading, reloadResults } =
    useLeadsSearch({
      page,
      query: searchQuery,
      orderBy,
      orderDirection,
      status: statusFilter ? statusFilter : undefined,
      priority: priorityFilter ? priorityFilter : undefined,
    })
  const navigate = useNavigate()

  const onNewTeacherClick = () => {
    navigate("/leads/new")
  }
  const clearFilters = () => {
    setStatusFilter(null)
    setPriorityFilter(null)
    setOrderBy("id")
    setOrderDirection("DESC")
  }
  const onStatusChange = async (leadId: number, status: Lead["status"]) => {
    await updateLead({ status, id: leadId })
    await reloadResults()
  }
  const onPriorityChange = async (leadId: number, priority: number) => {
    await updateLead({ priority, id: leadId })
    await reloadResults()
  }

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <div className={classes.left}>
          <Input
            type="text"
            placeholder="Search"
            className={classes.input}
            value={searchQuery}
            onInput={(e) => setSearchQuery(e.currentTarget.value)}
          />
          <div className={classes.dropdown}>
            <div className={classes.dropdownLabel}>Order By</div>
            <Dropdown placement="bottom-end">
              <DropdownTrigger>
                <Chip color="default" variant="flat">
                  {orderBy === "visibleId" ? "id" : orderBy}
                </Chip>
              </DropdownTrigger>
              <DropdownMenu variant="flat">
                <DropdownItem
                  key="visibleId"
                  onClick={() => setOrderBy("visibleId")}
                >
                  Id
                </DropdownItem>
                <DropdownItem
                  key="firstSeenDate"
                  onClick={() => setOrderBy("firstSeenDate")}
                >
                  First Seen
                </DropdownItem>
                <DropdownItem
                  key="priority"
                  onClick={() => setOrderBy("priority")}
                >
                  Priority
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className={classes.dropdown}>
            <Button
              size="sm"
              onClick={() => setOrderDirection("ASC")}
              variant={orderDirection === "DESC" ? "bordered" : "solid"}
            >
              Low {">"} Hight
            </Button>
            <Button
              size="sm"
              onClick={() => setOrderDirection("DESC")}
              variant={orderDirection === "DESC" ? "solid" : "bordered"}
            >
              High {">"} Low
            </Button>
          </div>
          <div className={classes.dropdown}>
            <Dropdown placement="bottom-end">
              <DropdownTrigger>
                <Chip
                  color={
                    statusFilter ? getColorByStatus(statusFilter) : "default"
                  }
                  variant="flat"
                >
                  {statusFilter ? statusLabels[statusFilter] : "All Statuses"}
                </Chip>
              </DropdownTrigger>
              <DropdownMenu aria-label="Profile Actions" variant="flat">
                {statuses.map((statusItem, index) => (
                  <DropdownItem
                    key={statusItem}
                    onClick={() => setStatusFilter(statusItem)}
                  >
                    <Chip color={getColorByStatus(statusItem)} variant="flat">
                      {statusLabels[statusItem]}
                    </Chip>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className={classes.dropdown}>
            <Dropdown placement="bottom-end">
              <DropdownTrigger>
                <Chip
                  color={
                    priorityFilter
                      ? getColorByPriority(priorityFilter)
                      : "default"
                  }
                  variant="flat"
                >
                  {priorityFilter
                    ? priorityMapping[priorityFilter]
                    : "All Priorities"}
                </Chip>
              </DropdownTrigger>
              <DropdownMenu aria-label="Profile Actions" variant="flat">
                {Object.entries(priorityMapping).map(([priority, label]) => (
                  <DropdownItem
                    key={priority}
                    onClick={() => setPriorityFilter(Number(priority))}
                  >
                    <Chip
                      color={getColorByPriority(Number(priority))}
                      variant="flat"
                    >
                      {label}
                    </Chip>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className={classes.dropdown}>
            <Button
              size="sm"
              color="danger"
              variant="flat"
              onClick={clearFilters}
            >
              Clear
            </Button>
          </div>
        </div>
        <div>
          <Button onClick={onNewTeacherClick}>Add Lead</Button>
        </div>
      </div>
      <LeadsTable
        leads={leads}
        page={page + 1}
        pages={totalPages}
        resultsCount={totalResults}
        onStatusChange={onStatusChange}
        onPriorityChange={onPriorityChange}
        loading={loading}
        onPageChange={(page) => setPage(page - 1)}
      />
    </div>
  )
}

export default Leads
