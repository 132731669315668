import { Button, Card, CardBody, CardHeader, Divider } from "@nextui-org/react"
import { Input } from "@nextui-org/react"
import Logo from "../components/Logo"
import classes from "../styles/Login.module.scss"
import { useState } from "react"
import { createOTP, validateOTP } from "../utils/requests/auth"
import { useAuth } from "../context/AuthContext"
import { TOKEN_COOKIE, setCookie } from "../utils/cookies"

const Login = () => {
  const [step, setStep] = useState(0)
  const [otp, setOTP] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [error, setError] = useState("")

  const { reloadSettings } = useAuth()

  const onLoginClick = async () => {
    if (!phoneNumber) {
      return
    }
    try {
      await createOTP(phoneNumber)
      setStep(1)
    } catch {
      setPhoneNumber("")
    }
  }

  const onSetOtpClick = async () => {
    if (!otp || !phoneNumber) {
      return
    }

    try {
      const res = await validateOTP(phoneNumber, Number(otp))

      if (!res.success || !res.token) {
        setError("Wrong Code")
        return
      }

      setCookie(TOKEN_COOKIE, res.token)
      await reloadSettings()
    } catch {
      setOTP("")
    }
  }
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader className="flex gap-3">
          <Logo height={35} width={17} />
          <div className="flex flex-col">
            <p className="text-md">Dio Dashboard</p>
            <p className="text-small text-default-500">
              Login to your account to proceed
            </p>
          </div>
        </CardHeader>
        <Divider />
        <CardBody className="flex gap-3">
          {step === 0 && (
            <>
              <Input
                type="phone"
                label="Phone Number"
                value={phoneNumber}
                onInput={(e) => setPhoneNumber(e.currentTarget.value)}
              />
              <Button fullWidth onClick={onLoginClick}>
                Login
              </Button>
            </>
          )}
          {step === 1 && (
            <>
              <Input
                type="number"
                label="OTP Code"
                value={otp}
                onInput={(e) => setOTP(e.currentTarget.value)}
              />
              {error && <p className={classes.error}>{error}</p>}
              <Button fullWidth onClick={onSetOtpClick}>
                Login
              </Button>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default Login
