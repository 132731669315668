import JoditEditor, { IJoditEditorProps } from "jodit-react"
import { useMemo, useRef, useState } from "react"
interface RichTextEditorProps {
  value: string
  onChange(newContent: string): void
}
const RichTextEditor = (props: RichTextEditorProps) => {
  const { value, onChange } = props
  const editor = useRef(null)

  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "|",
    "undo",
    "redo",
  ]

  const config: IJoditEditorProps["config"] = {
    readonly: false,
    defaultActionOnPaste: "insert_as_html",
    enter: "div",
    // options that we defined in above step.
    buttons: options,
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    direction: "rtl",
    theme: "light",
    showTooltip: true,
  }

  return (
    <JoditEditor
      ref={editor}
      value={value || ""}
      config={config}
      onBlur={onChange}
    />
  )
}

export default RichTextEditor
