import { useEffect, useState } from "react"
import { getStats } from "../utils/requests/stats"

const useStats = () => {
  const [stats, setStats] = useState<StatsCounters>({
    events: 0,
    liveEvents: 0,
    liveTests: 0,
    students: 0,
    teachers: 0,
    subscriptions: 0,
    totalCompanyFee: 0,
  })
  const fetchStats = async () => {
    const stats = await getStats()
    setStats(stats)
  }
  useEffect(() => {
    fetchStats()
  }, [])

  return stats
}

export default useStats
