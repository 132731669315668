import { BASE_URL, requests } from "./base"

const GET_TEACHERS = `${BASE_URL}/admin/teachers`
const SEARCH_TEACHERS = `${BASE_URL}/admin/teachers/search`
const GET_TEACHER_BY_UID = `${BASE_URL}/admin/teachers`
const NEW_TEACHER = `${BASE_URL}/admin/teachers/create`
const GET_SUBSCRIPTION_PRICE = `${BASE_URL}/admin/teachers/subscription/price`
const ADD_CREDIT_CARD = `${BASE_URL}/admin/teachers/subscription/card/link`

interface GetAllTeachersResponse {
  success: boolean
  teachers: TeachersSearchResult
  currentPage: number
  totalPages: number
}

export const getAllTeachers = async (page: number = 0) => {
  const res = await requests.get<GetAllTeachersResponse>(
    `${GET_TEACHERS}?page=${page}`
  )
  return res
}
interface SearchTeachersResponse {
  success: boolean
  teachers: TeachersSearchResult
  currentPage: number
  totalPages: number
}

export const searchTeachers = async (query: string) => {
  const res = await requests.post<SearchTeachersResponse>(
    `${SEARCH_TEACHERS}`,
    { query }
  )
  return res
}

interface GetTeacherByUIDResponse {
  success: boolean
  teacher: TeacherDetails
  user: UserDetails
}
export const getTeacherByUID = async (uid: number) => {
  const res = await requests.post<GetTeacherByUIDResponse>(
    `${GET_TEACHER_BY_UID}`,
    { uid }
  )
  return res
}

interface CreateTeacherResponse {
  success: boolean
  teacherId: number
}
export const createNewTeacher = async (
  details: Partial<TeacherDetails & { phoneNumber: string; trialDays: number }>
) => {
  const res = await requests.post<CreateTeacherResponse>(
    `${NEW_TEACHER}`,
    details
  )
  return res
}
interface GetSubscriptionPriceResponse {
  firstChargePrice: number
  firstCharge: string
}
export const getSubscriptionPrice = async (trialDays: number) => {
  const res = await requests.post<GetSubscriptionPriceResponse>(
    GET_SUBSCRIPTION_PRICE,
    {
      trialDays,
    }
  )
  return res
}
interface GetAddCreditCardLinkResponse {
  success: boolean
  url: string
  error?: { id: number; message: string }
}
export const getAddCreditCardLink = async (teacherUID: number) => {
  const res = await requests.post<GetAddCreditCardLinkResponse>(
    ADD_CREDIT_CARD,
    {
      teacherUID,
    }
  )
  return res
}
