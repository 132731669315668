const Logo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="175"
      height="372"
      viewBox="0 0 175 372"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M87.3839 0H2.2166C0.991928 0 0 0.991928 0 2.2166V283.664C0 331.925 39.123 371.048 87.3839 371.048C135.645 371.048 174.768 331.925 174.768 283.664V87.3839C174.768 39.123 135.645 0 87.3839 0ZM31.9301 36.4409C31.9301 33.9915 33.914 32.0077 36.3633 32.0077H87.3839C118.012 32.0077 142.838 56.8336 142.838 87.4614C142.838 118.089 118.012 142.915 87.3839 142.915H36.3633C33.914 142.915 31.9301 140.931 31.9301 138.482V36.4409ZM142.843 179.079V192.063C142.843 194.512 140.859 196.496 138.41 196.496H36.3633C33.914 196.496 31.9301 194.512 31.9301 192.063V179.079C31.9301 176.63 33.914 174.646 36.3633 174.646H138.41C140.859 174.646 142.843 176.63 142.843 179.079ZM87.3894 341.777C55.2542 341.777 29.2037 315.727 29.2037 283.592C29.2037 251.456 55.2542 225.406 87.3894 225.406C119.525 225.406 145.575 251.456 145.575 283.592C145.575 315.727 119.525 341.777 87.3894 341.777Z"
        fill="white"
      />
      <path
        d="M55.7862 121.276H86.8407C105.172 121.276 120.926 106.291 121.192 87.96C121.464 69.0857 106.192 53.6471 87.3838 53.6471H55.7862C54.5615 53.6471 53.5696 54.639 53.5696 55.8637V119.059C53.5696 120.284 54.5615 121.276 55.7862 121.276Z"
        fill="white"
      />
      <path
        d="M87.3838 319.063C106.796 319.063 122.534 303.179 122.534 283.586C122.534 263.993 106.796 248.109 87.3838 248.109C67.9712 248.109 52.2341 263.993 52.2341 283.586C52.2341 303.179 67.9712 319.063 87.3838 319.063Z"
        fill="white"
      />
    </svg>
  )
}

export default Logo
