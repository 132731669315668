import { useEffect, useState } from "react"
import TeachersTable from "../components/Tables/TeachersTable"
import useTeachersSearch from "../hooks/useTeachersSearch"
import { Button, Input } from "@nextui-org/react"
import classes from "../styles/Teachers.module.scss"
import { useNavigate } from "react-router-dom"

const Teachers = () => {
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState("")
  const { teachers, totalPages, loading } = useTeachersSearch(page, searchQuery)

  const navigate = useNavigate()

  const onNewTeacherClick = () => {
    navigate("/teachers/new")
  }

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <Input
          type="text"
          placeholder="Search"
          className={classes.input}
          value={searchQuery}
          onInput={(e) => setSearchQuery(e.currentTarget.value)}
        />
        <div>
          <Button onClick={onNewTeacherClick}>New Teacher</Button>
        </div>
      </div>
      <TeachersTable
        teachers={teachers}
        page={page + 1}
        pages={totalPages}
        loading={loading}
        onPageChange={(page) => setPage(page - 1)}
      />
    </div>
  )
}

export default Teachers
