import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Input,
} from "@nextui-org/react"
import classes from "../styles/NewTeacher.module.scss"
import { useEffect, useState } from "react"
import {
  createNewTeacher,
  getSubscriptionPrice,
} from "../utils/requests/teachers"
import { useNavigate } from "react-router-dom"
import { addDays } from "../utils/dates"
import { formatPrice } from "../utils/currencies"

const NewTeacher = () => {
  const [loading, setLoading] = useState(false)

  const [phoneNumber, setPhoneNumber] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [idNumber, setIdNumber] = useState("")
  const [lessonPrice, setLessonPrice] = useState("120")
  const [lessonDuration, setLessonDuration] = useState("40")
  const [trialDays, setTrialDays] = useState("30")
  const [firstChargePrice, setFirstChargePrice] = useState(79)
  const [firstChargeDate, setFirstChargeDate] = useState(addDays(30))

  const navigate = useNavigate()

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await createNewTeacher({
      phoneNumber,
      firstName,
      lastName,
      idNumber,
      lessonPrice: Number(lessonPrice),
      lessonDuration: Number(lessonDuration),
      trialDays: Number(trialDays),
    })

    setLoading(false)

    if (!res.success) {
      return
    }

    if (res.teacherId) {
      navigate(`/teachers/${res.teacherId}`)
      return
    }

    navigate(-1)
  }

  useEffect(() => {
    const fetchInfo = async () => {
      const res = await getSubscriptionPrice(Number(trialDays))
      setFirstChargePrice(res.firstChargePrice)
      setFirstChargeDate(new Date(res.firstCharge))
    }

    fetchInfo()
  }, [trialDays])

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <form onSubmit={onSubmit}>
          <CardHeader>New Teacher</CardHeader>
          <Divider />
          <CardBody>
            <div className={classes.form}>
              <Input
                type="tel"
                label="Phone Number"
                disabled={loading}
                required
                value={phoneNumber}
                onInput={(e) => setPhoneNumber(e.currentTarget.value)}
              />
              <Input
                type="text"
                label="First Name"
                disabled={loading}
                required
                value={firstName}
                onInput={(e) => setFirstName(e.currentTarget.value)}
              />
              <Input
                type="text"
                label="Last Name"
                disabled={loading}
                required
                value={lastName}
                onInput={(e) => setLastName(e.currentTarget.value)}
              />
              <Input
                type="text"
                label="ID Number"
                disabled={loading}
                value={idNumber}
                onInput={(e) => setIdNumber(e.currentTarget.value)}
              />
              <Input
                type="number"
                label="Lesson Price"
                disabled={loading}
                value={lessonPrice}
                onInput={(e) => setLessonPrice(e.currentTarget.value)}
              />
              <Input
                type="number"
                label="Lesson Duration (Minutes)"
                disabled={loading}
                value={lessonDuration}
                onInput={(e) => setLessonDuration(e.currentTarget.value)}
              />
              <Input
                type="number"
                label="Trial Days"
                disabled={loading}
                value={trialDays}
                onInput={(e) => setTrialDays(e.currentTarget.value)}
              />
            </div>
          </CardBody>
          <CardFooter className={classes.footer}>
            <div>
              <div>
                <b>First Charge:</b>{" "}
                {firstChargeDate.toLocaleDateString("he-IL")}
              </div>
              <div>
                <b>First Charge Price:</b> {formatPrice(firstChargePrice)}
              </div>
            </div>
            <Button type="submit" fullWidth disabled={loading}>
              Add New Teacher
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  )
}

export default NewTeacher
