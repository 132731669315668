import { createContext, useContext, useEffect, useState } from "react"
import { TOKEN_COOKIE, deleteCookie, getCookie } from "../utils/cookies"
import { getSettings } from "../utils/requests/settings"

interface AuthState {
  user: UserDetails | null
  token: string
  isLoggedIn: boolean
  loading: boolean
}
interface AuthContext {
  auth: AuthState
  setAuth: React.Dispatch<React.SetStateAction<AuthState>>
}

const defaultContext: AuthContext = {
  auth: {
    user: null,
    token: "",
    loading: true,
    isLoggedIn: false,
  },
  setAuth: () => {},
}
const AuthContext = createContext(defaultContext)

interface AuthContextProviderProps {
  children: any
}
export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [auth, setAuth] = useState<AuthState>(defaultContext.auth)

  const pushToAuth = (value: Partial<AuthState>) => {
    setAuth((prevAuth) => ({ ...prevAuth, ...value }))
  }

  const loadSettings = async () => {
    pushToAuth({ loading: true })
    const settings = await getSettings()
    const user = settings.user
    pushToAuth({ user, isLoggedIn: !!user, loading: false })
  }

  useEffect(() => {
    const token = getCookie(TOKEN_COOKIE)
    if (!token) {
      pushToAuth({ loading: false })
      return
    }

    pushToAuth({ token })
    loadSettings()
  }, [])
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const { auth, setAuth } = useContext(AuthContext)

  const pushToAuth = (value: Partial<AuthState>) => {
    setAuth((prevAuth) => ({ ...prevAuth, ...value }))
  }
  const loadSettings = async () => {
    pushToAuth({ loading: true })
    const settings = await getSettings()
    const user = settings.user
    pushToAuth({ user, isLoggedIn: !!user, loading: false })
  }

  const logout = async () => {
    pushToAuth({
      user: null,
      token: "",
    })
    deleteCookie(TOKEN_COOKIE)
    window.location.reload()
  }

  return { auth, reloadSettings: loadSettings, logout }
}
