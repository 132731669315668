import { BASE_URL, requests } from "./base"

const ATTACH_INVOICES_ACCOUNT = `${BASE_URL}/admin/teachers/invoices/attach`

interface AttachInvoicesAccountResponse {
  success: boolean
}
export const attachInvoicesAccount = async (
  email: string,
  password: string,
  teacherId: number
) => {
  const res = await requests.post<AttachInvoicesAccountResponse>(
    `${ATTACH_INVOICES_ACCOUNT}`,
    { email, password, teacherId }
  )
  return res
}
