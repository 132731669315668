import { useNavigate, useParams } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Chip,
  DateInput,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Textarea,
} from "@nextui-org/react"
import { I18nProvider } from "@react-aria/i18n"

import classes from "../styles/LeadDetails.module.scss"
import { getLead, sendWhatsapp, updateLead } from "../utils/requests/leads"
import { useEffect, useState } from "react"

import {
  getColorByPriority,
  getColorByStatus,
  priorityMapping,
  statuses,
  statusLabels,
} from "../components/Tables/LeadsTable"
import { parseDate } from "@internationalized/date"
import RichTextEditor from "../components/RichTextEditor"

const LeadDetails = () => {
  const { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [lead, setLead] = useState<Partial<Lead>>({})
  const [showWhatsappButton, setShowWhatsappButton] = useState(false)
  const fetch = async () => {
    setLoading(true)
    const leadId = Number(id)
    if (isNaN(leadId)) {
      setLoading(false)
      return
    }
    const leadResult = await getLead(leadId)
    if (leadResult) {
      setLead(leadResult)
    }
    setLoading(false)
  }

  const onEditField = (name: string, value: string) => {
    setLead((prevLead) => ({ ...prevLead, [name]: value }))
  }
  useEffect(() => {
    fetch()
  }, [id])

  const onEdit = async () => {
    setLoading(true)
    const mappedLead: Partial<Lead> = {}

    for (const entry of Object.entries(lead)) {
      const [key, value] = entry

      if (key === "id") {
        mappedLead[key] = Number(id)
        continue
      }
      if (key === "firstSeenDate") {
        continue
      }
      // @ts-ignore
      if (dateFields.includes(key) && value) {
        // @ts-ignore
        mappedLead[key] = new Date(value).toISOString().split("T")[0]
        continue
      }
      mappedLead[key] = value
    }
    await updateLead(mappedLead)
    await fetch()
    setLoading(false)
  }

  const onSendWhatsapp = async () => {
    setLoading(true)
    await sendWhatsapp(Number(id))
    await fetch()
    setLoading(false)
  }

  const dateFields: (keyof Lead)[] = [
    "joinedDate",
    "reminderDate",
    "firstSeenDate",
    "firstMessageDate",
  ]
  const rows: (keyof Lead)[][] = [
    ["firstName", "lastName", "phoneNumber", "status", "priority"],
    ["fileUrl", "fileName", "remindersSent"],
    ["firstSeenDate", "firstMessageDate", "reminderDate", "joinedDate"],
    ["teachingArea"],
  ]
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Card>
          <CardBody className={classes.details}>
            {rows.map((row, index) => (
              <div className={classes.row} key={index}>
                {row.map((field) => {
                  if (dateFields.includes(field)) {
                    return (
                      <I18nProvider locale="en-GB">
                        <DateInput
                          isDisabled={loading}
                          onChange={(date) =>
                            onEditField(
                              field,
                              new Date(date.toString()).toISOString()
                            )
                          }
                          label={field}
                          value={
                            lead?.[field]
                              ? parseDate(
                                  new Date(lead?.[field] as string)
                                    ?.toISOString()
                                    ?.split("T")?.[0]
                                )
                              : undefined
                          }
                        />
                      </I18nProvider>
                    )
                  }
                  if (field === "status") {
                    return (
                      <Dropdown placement="bottom-end">
                        <DropdownTrigger>
                          <Chip
                            color={getColorByStatus(lead?.status)}
                            variant="flat"
                          >
                            {statusLabels[lead.status ?? "PENDING"]}
                          </Chip>
                        </DropdownTrigger>
                        <DropdownMenu variant="flat">
                          {statuses.map((statusItem) => (
                            <DropdownItem
                              key={statusItem}
                              onClick={() => onEditField("status", statusItem)}
                            >
                              <Chip
                                color={getColorByStatus(statusItem)}
                                variant="flat"
                              >
                                {statusLabels[statusItem]}
                              </Chip>
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    )
                  }
                  if (field === "priority") {
                    return (
                      <Dropdown placement="bottom-end">
                        <DropdownTrigger>
                          <Chip
                            color={getColorByPriority(lead?.priority ?? 1)}
                            variant="flat"
                          >
                            {priorityMapping[lead?.priority ?? 1]}
                          </Chip>
                        </DropdownTrigger>
                        <DropdownMenu variant="flat">
                          {Object.entries(priorityMapping).map(
                            ([priority, label]) => (
                              <DropdownItem
                                key={priority}
                                onClick={() =>
                                  onEditField("priority", priority)
                                }
                              >
                                <Chip
                                  color={getColorByPriority(Number(priority))}
                                  variant="flat"
                                >
                                  {label}
                                </Chip>
                              </DropdownItem>
                            )
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    )
                  }

                  return (
                    <Input
                      key={field}
                      disabled={loading}
                      type="text"
                      // @ts-ignore
                      value={lead?.[field]}
                      label={field}
                      className={classes.input}
                      onChange={(e) => onEditField(field, e.target.value)}
                    />
                  )
                })}
              </div>
            ))}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {/* <Textarea
              style={{ direction: "rtl" }}
              value={lead?.comments}
              label="comments"
              onChange={(e) => onEditField("comments", e.target.value)}
            /> */}
            <div className={classes.richText}>
              <RichTextEditor
                value={lead?.comments ?? ""}
                onChange={(text) => onEditField("comments", text)}
              />
            </div>
          </CardBody>
        </Card>
        {lead?.status === "PENDING" && (
          <Card>
            <CardBody>
              <div className={classes.actions}>
                <Textarea
                  style={{ direction: "rtl" }}
                  value={lead?.message}
                  label="Whatsapp Message Content"
                  readOnly={lead?.status !== "PENDING"}
                  onChange={(e) => onEditField("message", e.target.value)}
                />
              </div>
            </CardBody>
          </Card>
        )}
      </div>
      <div className={classes.right}>
        <Card>
          <CardBody>
            <div className={classes.actions}>
              <Button
                fullWidth
                variant="flat"
                color="success"
                onPress={onEdit}
                isLoading={loading}
              >
                Save
              </Button>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody className={classes.details}>
            <div className={classes.row}>
              {lead.status === "PENDING" ? (
                <p style={{ marginBottom: 16, fontSize: 12 }}>Preview:</p>
              ) : (
                <p style={{ marginBottom: 16, fontSize: 12 }}>Message Sent:</p>
              )}
            </div>
            {lead.fileUrl && lead.fileName && (
              <img
                src={lead.fileUrl}
                style={{
                  width: 280,
                  height: "auto",
                  marginBottom: 12,
                  borderRadius: 8,
                }}
                alt={lead.fileName}
              />
            )}
            <div style={{ direction: "rtl", width: 280 }}>
              {lead.message?.split("\n").map((line) => (
                <div
                  style={{
                    height: "auto",
                    direction: "rtl",
                    textAlign: "right",
                    marginBottom: 16,
                    width: 280,
                    fontSize: 14,
                  }}
                >
                  {line}
                </div>
              ))}
            </div>
            {lead.status === "PENDING" && (
              <>
                <Divider style={{ marginBottom: 16 }} />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 16,
                    flexDirection: "column",
                  }}
                >
                  <Checkbox
                    checked={showWhatsappButton}
                    onValueChange={setShowWhatsappButton}
                  >
                    This message is ready
                  </Checkbox>
                  {showWhatsappButton && (
                    <Button
                      isLoading={loading}
                      onClick={onSendWhatsapp}
                      style={{ width: 280 }}
                    >
                      Send on Whatsapp
                    </Button>
                  )}
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default LeadDetails
