import { BASE_URL, requests } from "./base"

const SEARCH_LEADS = `${BASE_URL}/admin/leads`
const GET_LEAD = `${BASE_URL}/admin/leads`
const UPDATE_LEAD = `${BASE_URL}/admin/leads/update`
const SEND_WHATSAPP = `${BASE_URL}/admin/leads/whatsapp`
const CREATE_LEAD = `${BASE_URL}/admin/leads/create`

interface SearchRetValue {
  leads: Lead[]
  pages: number
  results: number
}
export const searchLead = async (
  query?: string,
  page: number = 0,
  orderBy: string = "id",
  orderDirection = "DESC",
  status?: Lead["status"],
  priority?: number
) => {
  const res = await requests.post<SearchRetValue>(SEARCH_LEADS, {
    query,
    page,
    orderBy,
    orderDirection,
    status,
    priority,
  })
  return res
}

export const updateLead = async (data: Partial<Lead>) => {
  const res = await requests.post(UPDATE_LEAD, data)
  return res
}
export const createLead = async (data: Partial<Lead>) => {
  const res = await requests.post<{
    success?: boolean
    id?: number
    error?: { code: string }
  }>(CREATE_LEAD, data)
  return res
}
export const getLead = async (leadId: number) => {
  const res = await requests.get<Lead>(`${GET_LEAD}/${leadId}`)
  return res
}
export const sendWhatsapp = async (id: number) => {
  const res = await requests.post<Lead>(SEND_WHATSAPP, { id })
  return res
}
