import { useEffect, useState } from "react"
import { getAllTeachers, searchTeachers } from "../utils/requests/teachers"

const useTeachersSearch = (page: number = 0, searchQuery?: string) => {
  const [loading, setLoading] = useState(false)

  const [teachers, setTeachers] = useState<TeachersSearchResult>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const fetchTeachers = async () => {
    setLoading(true)
    const teachersRes = await getAllTeachers(page)
    if (!teachersRes.success) {
      setLoading(false)
      return
    }

    setTeachers(teachersRes.teachers)
    setTotalPages(teachersRes.totalPages)
    setCurrentPage(teachersRes.currentPage)

    setLoading(false)
  }
  const fetchSearchResults = async (query: string) => {
    if (!query) {
      return
    }

    setLoading(true)
    const teachersRes = await searchTeachers(query)
    if (!teachersRes.success) {
      setLoading(false)
      return
    }

    setTeachers(teachersRes.teachers)
    setTotalPages(teachersRes.totalPages)
    setCurrentPage(teachersRes.currentPage)

    setLoading(false)
  }

  useEffect(() => {
    if (!searchQuery || searchQuery.length < 3) {
      fetchTeachers()
      return
    }
    fetchSearchResults(searchQuery)
  }, [searchQuery])

  return { teachers, loading, currentPage, totalPages }
}

export default useTeachersSearch
