import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
} from "@nextui-org/react"
import { Input } from "@nextui-org/react"
import Logo from "../components/Logo"
import classes from "../styles/Login.module.scss"
import { useAuth } from "../context/AuthContext"

const NoAccess = () => {
  const {
    logout,
    auth: { loading },
  } = useAuth()

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader className="flex gap-3">
          <Logo height={35} width={17} />
          <div className="flex flex-col">
            <p className="text-md">התחברות</p>
            <p className="text-small text-default-500">
              התחבר למשתמש שלך כדי להמשיך
            </p>
          </div>
        </CardHeader>
        <Divider />
        <CardBody className="flex gap-3">
          <h1>אין גישה</h1>
          <p>אין לך גישה לתוכן זה</p>
        </CardBody>
        <Divider />
        <CardFooter>
          <Button
            color="danger"
            variant="flat"
            fullWidth
            onClick={logout}
            isLoading={loading}
          >
            התנתק
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}

export default NoAccess
