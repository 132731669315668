import { BASE_URL, Response, requests } from "./base"

const CREATE_OTP = `${BASE_URL}/auth/otp/create`
const VALIDATE_OTP = `${BASE_URL}/auth/otp/validate`

const DELETE_TOKEN = `${BASE_URL}/auth/token/delete`

export const createOTP = async (phoneNumber: string) => {
  const res = await requests.post(CREATE_OTP, { phoneNumber })

  return res
}

interface ValidateOtpResponse extends Response {
  token: string
}

export const validateOTP = async (phoneNumber: string, otp: number) => {
  const res = await requests.post<ValidateOtpResponse>(VALIDATE_OTP, {
    phoneNumber,
    otp,
  })
  return res
}

export const destroyToken = async (token: string) => {
  const res = await requests.delete<ValidateOtpResponse>(DELETE_TOKEN)

  return res
}
