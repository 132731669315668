import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Textarea,
} from "@nextui-org/react"
import classes from "../styles/NewTeacher.module.scss"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { createLead } from "../utils/requests/leads"
import {
  getColorByPriority,
  priorityMapping,
} from "../components/Tables/LeadsTable"

const NewLead = () => {
  const [loading, setLoading] = useState(false)

  const [lead, setLead] = useState<Partial<Lead>>({})
  const [phoneNumberError, setPhoneNumberError] = useState<string>("")
  const navigate = useNavigate()

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const { id, success, error } = await createLead(lead)
    setLoading(false)

    if (error?.code === "ALREADY_EXISTS") {
      setPhoneNumberError("The phone number already exists")
      console.log("The phone number already exists")
      return
    }

    if (!success) {
      return
    }
    if (id) {
      navigate(`/lead/${id}`)
    } else {
      navigate(-1)
    }
  }

  const onFieldChange = (name: string, value: string) => {
    if (name === "phoneNumber") {
      setPhoneNumberError("")
    }
    // @ts-ignore
    setLead((prevLead) => ({ ...prevLead, [name]: value }))
  }
  useEffect(() => {
    console.log(lead)
  }, [lead])
  const fields: (keyof Lead)[] = ["firstName", "lastName", "phoneNumber"]
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <form onSubmit={onSubmit}>
          <CardHeader>New Teacher</CardHeader>
          <Divider />
          <CardBody>
            <div className={classes.form}>
              <Input
                key="firstName"
                type="string"
                label="First Name"
                disabled={loading}
                value={lead?.firstName}
                onInput={(e) =>
                  onFieldChange("firstName", e.currentTarget.value)
                }
              />
              <Input
                key="lastName"
                type="string"
                label="Last Last"
                disabled={loading}
                value={lead?.lastName}
                onInput={(e) =>
                  onFieldChange("lastName", e.currentTarget.value)
                }
              />
              <Input
                key="phoneNumber"
                type="string"
                label="Phone Number"
                errorMessage={phoneNumberError}
                disabled={loading}
                placeholder="+972555049898"
                isInvalid={!!phoneNumberError}
                value={lead?.phoneNumber}
                onInput={(e) =>
                  onFieldChange("phoneNumber", e.currentTarget.value)
                }
              />
              <Input
                key="teachingArea"
                type="string"
                label="Teaching Area"
                disabled={loading}
                value={lead?.teachingArea}
                onInput={(e) =>
                  onFieldChange("teachingArea", e.currentTarget.value)
                }
              />
              <Textarea
                key="comments"
                type="string"
                label="Comments"
                disabled={loading}
                value={lead?.comments}
                onInput={(e) =>
                  onFieldChange("comments", e.currentTarget.value)
                }
              />

              <p>Priority:</p>
              <Dropdown placement="bottom-end">
                <DropdownTrigger>
                  <Chip
                    color={getColorByPriority(lead.priority ?? 3)}
                    variant="flat"
                  >
                    {priorityMapping[lead.priority ?? 3]}
                  </Chip>
                </DropdownTrigger>
                <DropdownMenu aria-label="Profile Actions" variant="flat">
                  {Object.entries(priorityMapping).map(([priority, label]) => (
                    <DropdownItem
                      key={priority}
                      onClick={() => onFieldChange("priority", priority)}
                    >
                      <Chip
                        color={getColorByPriority(Number(priority))}
                        variant="flat"
                      >
                        {label}
                      </Chip>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </CardBody>
          <CardFooter className={classes.footer}>
            <Button type="submit" fullWidth disabled={loading}>
              Create Lead
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  )
}

export default NewLead
