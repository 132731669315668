import Header from "./components/Header"
import Router from "./renderers/Router"

function App() {
  return (
    <>
      <Header />
      <main>
        <Router />
      </main>
    </>
  )
}

export default App
