import { useEffect, useState } from "react"
import { searchLead } from "../utils/requests/leads"

interface UseLeadsSearchProps {
  query?: string
  page?: number
  orderBy?: string
  orderDirection?: "ASC" | "DESC"
  status?: Lead["status"]
  priority?: number
}
export const useLeadsSearch = (props: UseLeadsSearchProps) => {
  const {
    query,
    page,
    status,
    priority,
    orderBy = "id",
    orderDirection = "DESC",
  } = props

  const [loading, setLoading] = useState<boolean>(false)
  const [leads, setLeads] = useState<Lead[]>([])
  const [totalPages, setTotalPages] = useState<number>(0)
  const [totalResults, setTotalResults] = useState<number>(0)

  const reloadResults = async () => {
    setLoading(true)
    const { pages, results, leads } = await searchLead(
      query,
      page,
      orderBy,
      orderDirection,
      status,
      priority
    )
    setLoading(false)
    setLeads(leads)
    setTotalPages(pages)
    setTotalResults(results)
  }
  useEffect(() => {
    reloadResults()
  }, [query, page, orderBy, orderDirection, status, priority])

  return { leads, totalPages, totalResults, loading, reloadResults }
}
